import { Fragment, useCallback } from 'react';
import { Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { GENDER, HEALTH_INSURANCE_LIST } from 'utils/Constants';
import GenericDatePicker from 'components/date-picker';
import { CreatePatientRequest, CreatePatientRequestForm, PatientsListRequest } from 'models/patients.model';
import { ArrowBack } from '@mui/icons-material';

interface CreatePatientFormProps {
  findPatientsFormValues: PatientsListRequest;
  handleSubmitCreatePatientForm: (data: CreatePatientRequestForm) => void;
  handleBackButton: () => void;
}

const CreatePatientForm = ({ handleSubmitCreatePatientForm, findPatientsFormValues, handleBackButton }: CreatePatientFormProps) => {
  const { register, control, handleSubmit } = useForm<CreatePatientRequest>();
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<CreatePatientRequestForm> = (data) => handleSubmitCreatePatientForm(data);

  const renderTextFields = useCallback(() => {
    const textFieldKeys = ['last_name', 'first_name', 'marital_name', 'cns'] as const;
    return (
      <Fragment>
        {textFieldKeys.map((key) => (
          <Grid key={`create_field_${key}`} item xs={12} md={6}>
            <TextField
              required={key !== 'marital_name'}
              variant='filled'
              label={t(`patients.create.fields.${key}`)}
              defaultValue={key === 'marital_name' ? findPatientsFormValues['birth_name'] : findPatientsFormValues[key]}
              fullWidth
              {...register(key)}
            />
          </Grid>
        ))}
      </Fragment>
    );
  }, [findPatientsFormValues, register, t]);

  return (
    <Card
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        title={
          <Typography variant='h2' mb={4}>
            {t('patients.create.title')}
          </Typography>
        }
      />
      <CardContent sx={{ display: 'flex', flex: '1 0 0' }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Grid container item xs={12}>
            <Grid container item spacing={{ xs: 2, md: 2 }}>
              {renderTextFields()}
              <Grid item xs={12} md={6}>
                <TextField label={t('patients.create.fields.gender')} select variant='filled' defaultValue={findPatientsFormValues['gender'] || ''} required sx={{ width: 1 }} {...register('gender')}>
                  {GENDER.map(({ label, value }) => {
                    return (
                      <MenuItem value={value} key={`gender-${value}`}>
                        {t(label)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField sx={{ width: 1 }} required select variant='filled' label={t('patients.create.fields.health_fund')} {...register('health_fund_id')} defaultValue={''}>
                  {HEALTH_INSURANCE_LIST.map(({ abbreviation: label, code }) => {
                    return (
                      <MenuItem value={label} key={`health-fund-${code}`}>
                        {t(label)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <GenericDatePicker
                  name={'birth_date'}
                  label={t('patients.search.fields.birth_date')}
                  variant={'filled'}
                  control={control}
                  defaultValue={findPatientsFormValues['birth_date']}
                  required
                />
              </Grid>
              <Grid
                item
                sx={{
                  width: 1,
                  gap: '20px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <Button variant='contained' startIcon={<ArrowBack />} onClick={handleBackButton}>
                  {t('patients.create.back_button')}
                </Button>
                <Button type='submit' variant='contained'>
                  {t('patients.create.create_button')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default CreatePatientForm;
