import { Box, Checkbox, FormControlLabel, Grid, IconButton, SvgIcon, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useIsDoctor, useIsMobileView, useNotification } from 'hooks';
import { FC, useMemo, useRef } from 'react';
import { AppointmentMedicalAct2023 } from 'models/appointment.medical.act.model';
import { AppointmentPatient } from 'models/appointment.model';
import { ReactComponent as AddIcon } from 'resources/icons/add.svg';
import { ReactComponent as CloseIcon } from 'resources/icons/close.svg';
import { MAX_SIZE_FILE } from 'utils/Constants';
import { Control, Controller, FormState, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control<{ patients: AppointmentPatient[] }>;
  setValue: UseFormSetValue<{ patients: AppointmentPatient[] }>;
  getValues: UseFormGetValues<{ patients: AppointmentPatient[] }>;
  register: UseFormRegister<{ patients: AppointmentPatient[] }>;
  watch: UseFormWatch<{ patients: AppointmentPatient[] }>;
  formState: FormState<{ patients: AppointmentPatient[] }>;
  patientIndex: number;
  medicalActs: AppointmentMedicalAct2023[];
};

const BookingReasonsOfPatient: FC<Props> = ({ formState, register, control, watch, setValue, medicalActs, patientIndex }) => {
  const { t } = useTranslation();
  const inputFile = useRef<HTMLInputElement>(null);
  const isMobileView = useIsMobileView();
  const watchedPatient = watch('patients')[patientIndex];
  const isDoctor = useIsDoctor();
  const { notification } = useNotification();

  const addPrescriptionOnClick = () => {
    inputFile.current?.click();
  };

  const onChangeFile = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    if (file && (!(file.type === 'application/pdf' || file.type.startsWith('image/')) || file.size > MAX_SIZE_FILE)) {
      notification(t('appointment.book.steps.reasons.medical_acts.notification.errorOnUploading'), 'error');
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setValue(`patients.${patientIndex}.prescriptions`, [
          ...watch(`patients.${patientIndex}.prescriptions`),
          {
            file_name: file.name,
            file_content: (reader.result as string).split(',')[1],
          },
        ]);
      };
    }
    event.target.value = null;
  };

  const deletePrescriptionOnClick = (idx: number) => {
    const currFiles = [...watch(`patients.${patientIndex}.prescriptions`)];
    currFiles.splice(idx, 1);
    setValue(`patients.${patientIndex}.prescriptions`, currFiles);
  };

  const formatFullName = useMemo(() => {
    if (watchedPatient.first_name !== '' && watchedPatient.last_name !== '') {
      return `${watchedPatient.last_name} ${watchedPatient.first_name}`;
    } else {
      return `${watchedPatient.birth_name} ${watchedPatient.first_name}`;
    }
  }, [watchedPatient.birth_name, watchedPatient.first_name, watchedPatient.last_name]);

  return (
    <Grid>
      <Grid item xs={12}>
        <Typography variant='h2' sx={{ mt: 4, mb: 1 }}>
          {formatFullName}
        </Typography>
        {/* Medical Options */}
        <Typography variant='h2' sx={{ mt: 2, mb: 1 }}>
          {t('appointment.book.steps.reasons.medical_acts.title')}
        </Typography>
        <Controller
          name={`patients.${patientIndex}.medical_acts`}
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              {...field}
              onChange={(e, value) => {
                field.onChange(value);
              }}
              orientation={isMobileView ? 'vertical' : 'horizontal'}
              fullWidth={isMobileView}
            >
              {medicalActs
                .filter((act) => !act.additional)
                .map((act, idx: number) => (
                  <ToggleButton key={idx} value={`${act.id}`}>
                    {act.name}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          )}
        />
        {formState.errors?.patients?.[patientIndex]?.medical_acts ? (
          <Typography variant='subtitle2' color='error' sx={{ mt: 2, mb: 1 }}>
            {t('appointment.book.steps.reasons.medical_acts.notification.errorNoMedicalAct')}
          </Typography>
        ) : null}
        {/* Prescription */}
        {!isDoctor && (
          <>
            <Box display='flex' alignItems='center' sx={{ mt: 4, mb: 1 }}>
              <Typography variant='h2'>{t('appointment.book.steps.reasons.prescription.title')}</Typography>
              <input type='file' accept='image/*, application/pdf' ref={inputFile} style={{ display: 'none' }} onChange={onChangeFile} />
              <IconButton sx={{ ml: 2 }} onClick={addPrescriptionOnClick}>
                <SvgIcon component={AddIcon} inheritViewBox></SvgIcon>
              </IconButton>
            </Box>
            <Box>
              {watchedPatient.prescriptions.map((file: { file_name?: string; file_content?: string }, idx: number) => (
                <Grid
                  item
                  display='flex'
                  alignItems='center'
                  sx={{
                    mb: 1,
                    border: '2px solid',
                    borderRadius: 4,
                    p: 1,
                  }}
                  xs={12}
                  key={idx}
                >
                  <Grid item xs={10}>
                    <Typography
                      variant='body1'
                      sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {file.file_name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} textAlign='end'>
                    <IconButton onClick={() => deletePrescriptionOnClick(idx)}>
                      <SvgIcon component={CloseIcon} inheritViewBox fontSize='small'></SvgIcon>
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </>
        )}

        {/* Options */}
        <Typography variant='h2' sx={{ mt: 4, mb: 1 }}>
          {t('appointment.book.steps.reasons.options_checkbox.title')}
        </Typography>
        <Grid sx={{ mb: 1, flexWrap: 'wrap' }} display='flex'>
          <Controller
            name={`patients.${patientIndex}.fasting`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                {...field}
                label={t(`appointment.book.steps.reasons.options_checkbox.options.fasting`)}
                sx={{
                  mr: '30px',
                  ml: 0,
                }}
                control={<Checkbox checked={field.value} />}
              />
            )}
          />
          {medicalActs
            .filter((act) => act.additional)
            .map((act, idx: number) => (
              <Controller
                key={idx}
                name={`patients.${patientIndex}.medical_acts`}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    {...field}
                    onChange={(e, checked) => field.onChange(checked ? [...field.value, `${act.id}`] : [...field.value.filter((v) => v !== `${act.id}`)])}
                    label={t(
                      // @ts-ignore
                      `appointment.book.steps.reasons.options_checkbox.options.${act.constkey}`,
                    )}
                    sx={{
                      mr: '30px',
                      ml: 0,
                    }}
                    control={<Checkbox checked={field.value.includes(`${act.id}`)} />}
                  />
                )}
              />
            ))}
        </Grid>
      </Grid>
      <TextField {...register(`patients.${patientIndex}.comment`)} multiline minRows={4} label={t(`appointment.book.steps.reasons.my_remarks`)} fullWidth variant='filled' />
    </Grid>
  );
};

export default BookingReasonsOfPatient;
