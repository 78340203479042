import { TFunction } from 'i18next';
import { RdvPatient } from 'models/appointment.model';
import { Gender, Person, RelationInfo, RelationUsualInfo, ShortenPerson } from 'models/profile.model';
import { MedicalStaff } from 'models/record.model';

export const getInitials = (person: Person | ShortenPerson | RelationInfo | RelationUsualInfo) => {
  // @see #96 Check that property exists before to use it
  let i = '';
  if ('first_name' in person) {
    i += person.first_name[0];
  }
  if ('birth_name' in person && person.birth_name) {
    i += person.birth_name[0];
  } else if ('last_name' in person && person.last_name) {
    i += person.last_name[0];
  } else if ('marital_name' in person && person.marital_name) {
    i += person.marital_name[0];
  }
  return i.toUpperCase();
};

export const getFullName = (person: Person | ShortenPerson | RelationInfo | RelationUsualInfo) => {
  return `${person?.first_name} ${person?.last_name?.toUpperCase()}`;
};

export const getDoctorFullName = (person: MedicalStaff) => {
  return `${person.title ?? ''} ${person.first_name ?? ''} ${person.last_name ?? ''}`;
};

export const getAppointmentTempPatientName = (patient: RdvPatient | null, index: number, t: TFunction<'translation', undefined>) => {
  const fullName = `${patient?.firstname || ''} ${patient?.birthname || patient?.lastname || ''}`.trim();

  return fullName || t('appointment.book.steps.patients.unknownPatients.new.title', { replace: { number: index + 1 } });
};

export const formatGender = (gender: any) => {
  if (gender === "M") gender = Gender.MALE_V4;
  if (gender === "F") gender = Gender.FEMALE_V4;
  return gender;
};
