import { Button, DialogContent, Grid, Typography } from '@mui/material';
import api from 'api';
import { useNotification } from 'hooks';
import { AppointmentItem2023 } from 'models/appointment.item.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const AppointmentCancelModal: FC<{
  appointment: AppointmentItem2023;
  closeModal: () => void;
}> = ({ appointment, closeModal }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();

  const handleAppointmentCancelation = async (id: number) => {
    await api.appointments
      .cancelAppointment2023(id)
      .then(() => {
        notification(t('appointment.list.notification.cancelation.success'), 'success');
      })
      .catch(() => notification(t('appointment.list.notification.cancelation.error'), 'error'))
      .finally(() => {
        closeModal();
      });
  };

  return (
    <DialogContent>
      <Typography variant='h2'>{t('appointment.list.modal.cancel.title')}</Typography>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant='body1'>{`${t('appointment.list.modal.cancel.subtitle')} ${appointment.date}`}</Typography>
          <Grid item xs={12} my={2} display='flex' justifyContent='flex-end'>
            <Button variant='outlined' sx={{ mr: 1 }} onClick={closeModal}>
              {t('common.words.noBtn')}
            </Button>
            <Button variant='danger' sx={{ mr: 1 }} onClick={() => handleAppointmentCancelation(appointment.id)}>
              {t('common.words.yesBtn')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default AppointmentCancelModal;
