import axios, { AxiosError } from 'axios';
import config from 'config';
import { PdfType } from 'models/file.model';
import { eSanteGetToken } from 'utils/func/eSante.func';
import createAnalysisService from './analysis.service';
import createAppointmentService from './appointments.service';
import createAuthenticationService from './authentication.service';
import createEudccCertificatesService from './eudccCertificates.service';
import createContactService from './contact.service';
import createDoctorService from './doctors.service';
import createEprescriptionService from './eSante.service';
import createInvoiceService from './invoices.service';
import createMyDSPService from './myDSP.service';
import createBNLService from './bnl.service';
import createPatientService from './patients.service';
import createPreRegistrationService from './preregistration.service';
import createPrescriptionService from './prescriptions.service';
import createQuestionnairesService from './questionnaires.service';
import createResultService from './results.service';
import createUserService from './users.service';
import createVideoService from './videos.service';
import createDisclaimerService from './disclaimer.service';

export type ApiError<T = any> = AxiosError<T>;

const apiConfig = {
  AUTHENTICATE: {
    MYLAB: '/oauth/v2/token',
    UNIQUE: (lang: string) => `/api/v4/${lang}/auth/public/unique/access`,
    VERIFY_TOKEN: (lang: string, token: string) => `/api/v4/${lang}/auth/public/token/verify/${token}`,
    TOKEN_RELATIVE: (lang: string) => `/api/v4/${lang}/auth/public/login/token`,
    ACTIVATE_NEW_PATIENT: (lang: string) => `/api/v4/${lang}/auth/public/activate/patient`,
    FORGOT_PWD: (lang: string, email: string) => `/api/v4/${lang}/reset-password/forgot/${email}`,
    RESET_PWD: (lang: string, token: string) => `/api/v4/${lang}/reset-password/reset-new/${token}`,
  },
  PUBLIC: {
    EMAIL_TOKEN_VALIDATION: (lang: string) => `/api/v4/${lang}/auth/public/email-validation`
  },
  ME: {
    GET: (lang: string) => `/api/v4/${lang}/user/me`,
    GET_PATIENT_DASHBOARD: (lang: string) => `/api/v4/${lang}/user/me/dashboard/patient`,
    GET_DOCTOR_DASHBOARD: (lang: string) => `/api/v4/${lang}/user/me/dashboard/doctor`,
    UPDATE: (lang: string) => `/api/v4/${lang}/user/me`,
    UPDATE_EMAIL: (lang: string) => `/api/v4/${lang}/user/me/username`,
    UPDATE_PASSWORD: (lang: string) => `/api/v4/${lang}/user/me/password`,
    GET_ALL_OFFICES: (lang: string) => `/api/v4/${lang}/user/me/oms`,
  },
  RELATIVES: {
    ADD_RELATION_WITH_TOKEN: (lang: string) => `/api/v4/${lang}/grant-access/add`,
    ADD_RELATION: (lang: string) => `/api/v4/${lang}/grant-access/request`,
    GET: (lang: string) => `/api/v4/${lang}/grant-access/accesses`,
  },
  INVOICES: {
    GET: (lang: string) => `/api/v4/${lang}/invoices`,
    GET_BY_REFERENCE: (lang: string, reference: string) => `/api/v4/${lang}/invoices/dossier/${reference}`,
    GET_BY_REFERENCE_AND_AMOUNT: (lang: string) => `/api/v4/${lang}/invoices-public`,
    GET_FILE_BY_REFERENCE: (lang: string, reference: string) => `/api/v4/${lang}/invoices/dossier/${reference}/file`,
  },
  RESULTS: {
    GET: (lang: string) => `/api/v4/${lang}/result/dossiers`,
    GET_BY_REFERENCE: (lang: string, reference: string) => `/api/v4/${lang}/result/dossiers/${reference}`,
    GET_QUESTIONNAIRE_BY_ID: (lang: string, id: string) => `/api/v4/${lang}/result/questionnaires/${id}`,
    GET_QUESTIONNAIRE_PDF_FILE_BY_ID: (lang: string, id: number) => `/api/v4/${lang}/result/questionnaires/${id}/pdf`,
    PUT_QUESTIONNAIRE_BY_ID: (lang: string, id: string) => `/api/v4/${lang}/result/questionnaires/${id}`,
    SIGN_QUESTIONNAIRE_BY_ID: (lang: string, id: string) => `/api/v4/${lang}/result/questionnaires/${id}/sign`,
    GET_CROSSTAB_BY_PATIENT_ID: (lang: string) => `/api/v4/${lang}/result/cross-tab`,
    GET_FREQUENT_ANALYSIS: (lang: string) => `/api/v4/${lang}/result/frequent-analyses`,
    GET_FORM_HISTORY_RESULT: (lang: string) => `/api/v4/${lang}/result/history`,
    MARK_ALL_READ: (lang: string) => `/api/v4/${lang}/result/all-read`,
    GET_PDF: (lang: string, type: PdfType, dossierId: string, filename: string) => `/api/v4/${lang}/result/dossiers/${dossierId}/${type}/${filename}`,
    MARK: (lang: string, reference: string | number) => `/api/v4/${lang}/result/dossiers/${reference}/tag`,
    SEARCH: (lang: string) => `/api/v4/${lang}/result/search`,
  },
  DOCTORS: {
    SEARCH: (lang: string) => `/api/v4/${lang}/directory/entries`,
    GET_SPECIALITIES: (lang: string, type: number) => `/api/v4/${lang}/directory/specialities/${type}`,
    GET_CITIES: (lang: string) => `/api/v4/${lang}/directory/cities`,
  },
  PATIENTS: {
    CREATE: (lang: string) => `/api/v4/${lang}/patient/patients`,
    SEARCH: (lang: string) => `/api/v4/${lang}/patient/search`,
    SEARCH_MPI: (lang: string) => `/api/v4/${lang}/external/mpi/search`,
    GET_BY_ID: (lang: string, id: number) => `/api/v4/${lang}/patient/patients/${id}`,
    DELETE_RELATIVE_FROM_PATIENT: (lang: string, invitationId: number) => `/api/v4/${lang}/grant-access/accesses/${invitationId}`,
  },
  PRESCRIPTIONS: {
    GET_EDITABLE: (lang: string) => `/api/v4/${lang}/prescription/profiles-doctor`,
    GET_BY_PATIENT_ID: (lang: string, id: string) => `/api/v4/${lang}/prescription/patients/${id}`,
    GET_PROFILE_BY_ID: (lang: string, id: string | number) => `/api/v4/${lang}/prescription/profiles/${id}`,
    POST_PROFILE: (lang: string) => `/api/v4/${lang}/prescription/profiles`,
    POST_CALCULATE: (lang: string, id: string | number) => `/api/v4/${lang}/prescription/orders/${id}/calculate`,
    POST_BASKET: (lang: string) => `/api/v4/${lang}/prescription/orders`,
    GET_BY_BASKET_ID: (lang: string, id: string) => `/api/v4/${lang}/prescription/orders/${id}`,
    PUT_PROFILE: (lang: string, id: string | number) => `/api/v4/${lang}/prescription/profiles/${id}`,
    DELETE_PROFILE: (lang: string, id: string | number) => `/api/v4/${lang}/prescription/profiles/${id}`,
    POST_PROFILE_ITEM: (lang: string, id: string | number, analysis_reference: string | number) => `/api/v4/${lang}/prescription/profiles/${id}/items/${analysis_reference}`,
    DELETE_PROFILE_ITEM: (lang: string, id: string | number, analysis_reference: string) => `/api/v4/${lang}/prescription/profiles/${id}/items/${analysis_reference}`,
    DELETE_PROFILE_ITEMS: (lang: string, id: string | number) => `/api/v4/${lang}/prescription/profiles/${id}/items`,
    GET_MOTIVATIONS: (lang: string) => `/api/v4/${lang}/prescription/home-motivations`,
    POST_VALID_PRESCRIPTION: (lang: string, id: string | number) => `/api/v4/${lang}/prescription/orders/${id}/validate`,
    GET_PRESCRIPTION_PDF_FILE: (lang: string, id: string | number) => `/api/v4/${lang}/prescription/orders/${id}/pdf`,
    PUT_PRESCRIPTION_BASKET: (lang: string, id: string | number) => `/api/v4/${lang}/prescription/orders/${id}`,
    GET_PRESCRIPTION_DOCTOR_FILE: (lang: string) => `/api/v4/${lang}/prescription/type/pdf`,
    DELETE_PRESCRIPTION: (lang: string, id: string) => `/api/v4/${lang}/prescription/orders/${id}`,
    ADD_ANALYSIS_TO_PRESCRIPTION: (lang: string, basketId: string) => `/api/v4/${lang}/prescription/orders/${basketId}/items`,
    DELETE_ALL_ANALYSIS_TO_PRESCRIPTION: (lang: string, basketId: string) => `/api/v4/${lang}/prescription/orders/${basketId}/items`,
    DELETE_ANALYSIS_BY_ID: (lang: string, basketId: string, itemId: string) => `/api/v4/${lang}/prescription/orders/${basketId}/items/${itemId}`,
    POST_CC: (lang: string, basketId: string, medicalStaffId: number) => `/api/v4/${lang}/prescription/orders/${basketId}/cc/${medicalStaffId}`,
    DELETE_CC: (lang: string, basketId: string, medicalStaffId: number) => `/api/v4/${lang}/prescription/orders/${basketId}/cc/${medicalStaffId}`,
    SEARCH_MEDICAL_STAFF: (lang: string) => `/api/v4/${lang}/medical-staff/oms/search`,
  },
  PREREGISTRATION: {
    PRE_REGISTER: (lang: string) => `/api/v4/${lang}/external/nurse/pre-register`,
  },
  EUDCC_CERTIFICATES: {
    GET: (lang: string) => `/api/v4/${lang}/result/eudcc`,
  },
  VIDEOS: {
    GET: (lang: string) => `/api/v4/${lang}/external/tv/playlist`,
  },
  BNL: {
    GET_CENTERS: '/jsonapi/node/centre_de_prelevement?include=field_prelevement_photo,field_prelevement_services',
    GET_FAQ: '/jsonapi/node/faq',
    GET_NEWS: (pager?: number, sortingFilter?: string) => `/jsonapi/node/article?include=field_image&sort=${sortingFilter}&page[limit]=${pager}`,
  },
  ANALYSIS: {
    SEARCH: (lang: string) => `/api/v4/${lang}/catalogue/search`,
    GET_BY_ID: (lang: string, id: string | number) => `/api/v4/${lang}/catalogue/analyses/${id}`,
  },
  APPOINTMENTS: {
    GET_TOKEN: '/user/login',
    GET_APPOINTMENTS: (lang: string) => `/api/v4/${lang}/external/myrdv/appointments`,
    GET_SLOTS_2023: (lang: string) => `/api/v4/${lang}/external/myrdv/slots`,
    GET_SLOTS: '/appointment/get-appointment-available',
    GET_MEDICAL_ACTS_2023: (lang: string) => `/api/v4/${lang}/external/myrdv/medical-acts`,
    GET_MEDICAL_ACTS: (lang: string) => `/analyse/get-all${lang ? `?lang=${lang}` : ''}`,
    POST_APPOINTMENT_2023: (lang: string) => `/api/v4/${lang}/external/myrdv/book`,
    POST_APPOINTMENT: '/appointment/create',
    GET_NEAREST_CENTERS: '/center/get-available-centers',
    GET_CENTER_SLOTS: '/center/get-available-slots',
    GET_APPOINTMENTS_BY_TOKEN: (token: string) => `/appointment/get-by-token/${token}`,
    CANCEL_APPOINTMENT_2023: (lang: string, id: number) => `/api/v4/${lang}/external/myrdv/appointments/${id}`,
    CANCEL_APPOINTMENT: '/appointment/delete',
  },
  MYDSP: {
    CHECK_IF_EXISTS: (lang: string) => `/api/v4/${lang}/external/dsp/patient/check`,
    GET_DOCUMENTS: (lang: string) => `/api/v4/${lang}/external/dsp/documents`,
    GET_DOCUMENT_PDF: (lang: string, uuid: string) => `/api/v4/${lang}/external/dsp/documents/${uuid}/pdf`,
  },
  CONTACT: {
    POST_CONTACT: (lang: string) => `/api/v4/${lang}/contact`,
  },
  EPRESCRIPTION: {
    TOKEN_GENERATE: `${eSanteGetToken}`,
  },
  DISCLAIMER: {
    ACCEPT: (lang: string, id: number) => `/api/v4/${lang}/disclaimer/accept/${id}}`,
    GET: (lang: string) => `/api/v4/${lang}/disclaimer`,
    GET_PUBLIC: (lang: string, client_id: string) => `/api/v4/${lang}/disclaimer/public/${client_id}`,
  },
};

export type APIConfiguration = typeof apiConfig;

// MYLAB
export const axiosMYLAB = axios.create({ baseURL: config.myLabApiUrl });
// BIONEXT
export const axiosBION = axios.create({ baseURL: config.websiteBionextApiUrl });
// EPRESCRIPTION
export const axiosePrescription = axios.create({
  baseURL: config.ePrescription.apiUrl,
});
// MYLAB
export const axiosMYRDV = axios.create({ baseURL: config.myRdvApiUrl });

export default {
  authentication: createAuthenticationService(apiConfig),
  users: createUserService(apiConfig),
  bnl: createBNLService(apiConfig),
  invoices: createInvoiceService(apiConfig),
  results: createResultService(apiConfig),
  patients: createPatientService(apiConfig),
  prescriptions: createPrescriptionService(apiConfig),
  doctors: createDoctorService(apiConfig),
  appointments: createAppointmentService(apiConfig),
  eudccCertificates: createEudccCertificatesService(apiConfig),
  videos: createVideoService(apiConfig),
  questionnaires: createQuestionnairesService(apiConfig),
  analysis: createAnalysisService(apiConfig),
  myDSP: createMyDSPService(apiConfig),
  contact: createContactService(apiConfig),
  preregistration: createPreRegistrationService(apiConfig),
  ePrescription: createEprescriptionService(apiConfig),
  disclaimer: createDisclaimerService(apiConfig),
};
