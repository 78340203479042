import { Box, Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { PatientsListRequest, PatientsListRequestForm } from 'models/patients.model';
import { FC, useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { convertDate } from 'utils/func/Date.func';
import { useQuery } from 'hooks';
import { GENDER } from 'utils/Constants';

interface FindPatientsFormProps {
  halfWidth?: boolean;
  handleFormAction: (data: PatientsListRequest) => void;
  showCreatePatientButton?: boolean;
  handleShowCreatePatientForm? : () => void;
}

const FindPatientsForm: FC<FindPatientsFormProps> = ({ 
    halfWidth = false, 
    handleFormAction,
    showCreatePatientButton,
    handleShowCreatePatientForm,
  }) => {

  const { t } = useTranslation();
  const query = useQuery();

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
  } = useForm<PatientsListRequestForm>({ defaultValues: { cns: query.get('cns') ?? '' } });

  
  const onSubmit: SubmitHandler<PatientsListRequestForm> = useCallback(
    (data) => {
      const { birth_date } = data;
      const birth_dateShort = birth_date ? convertDate(birth_date, false, 'yyyy-MM-dd') : '';
      const formattedData = {
        ...data,
        birth_date: birth_dateShort,
      }
      handleFormAction(formattedData);
    },
    [handleFormAction],
  );

  return (
    <Card
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        title={
          <Typography variant='h2' mb={4}>
            {t('patients.search.title')}
          </Typography>
        }
      />
      <CardContent sx={{ display: 'flex', flex: '1 0 0' }}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Grid container item xs={12}>
            <Grid container item spacing={{ xs: 2, md: 2 }}>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <TextField 
                  variant='filled' 
                  defaultValue={undefined} 
                  label={t('patients.search.fields.patient_name')}
                  fullWidth 
                  {...register('last_name')} 
                />
              </Grid>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <TextField 
                  variant='filled' 
                  label={t('patients.search.fields.patient_first_name')}
                  fullWidth 
                  {...register('first_name')}
                />
              </Grid>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <TextField variant='filled' {...register('birth_name')} label={t('patients.search.fields.birth_name')} fullWidth />
              </Grid>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <TextField variant='filled'
                  {...register('cns', {onChange: () => clearErrors()})}
                  label={t('patients.search.fields.cns')} 
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <TextField select variant='filled' label={t('patients.search.fields.sex')} {...register('gender')} defaultValue={''} fullWidth>
                  {GENDER.map(({ label, value }, i) => {
                    return (
                      <MenuItem value={value} key={`sex-${i}`}>
                        {t(label)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12} md={halfWidth ? 6 : 4}>
                <Controller
                  name='birth_date'
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <DatePicker
                      label={t('patients.search.fields.birth_date')}
                      openTo='year'
                      views={['year', 'month', 'day']}
                      slotProps={{ 
                        textField: { 
                          variant: 'filled', 
                          fullWidth: true,
                        } 
                      }}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: (theme) => theme.spacing(6),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '20px',
                justifyContent: 'flex-end',
                mt: 6,
              }}
            >
              {showCreatePatientButton && (
                <Button type='button' variant='contained' onClick={handleShowCreatePatientForm}>
                  {t('patients.search.create_button')}
                </Button>
              )}
              <Button type='submit' variant='contained'>
                {t('patients.search.button')}
              </Button>
            </Box>
          </Box>
        </form>
      </CardContent>
    </Card>
  );
};

export default FindPatientsForm;
